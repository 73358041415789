import debounce from 'lodash.debounce';
import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
import Popup from '../components/popup/_popup';
class GalleriesGrid {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.galleries = this.root.querySelectorAll(
            '[data-galleries-grid-item]'
        );
        this.gridEl = this.root.querySelector('[data-galleries-grid-list]');
        this.moreEl = this.root.querySelector('[data-galleries-grid-more]');

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));

        this.currGallery = null;
        this.currPage = 1;
        this.lastIndex = this.settings.lastIndex;
        this.maxPages = this.settings.maxPages;
        this.galleriesData = JSON.parse(
            this.root.getAttribute('data-galleries-grid')
        );

        this.html = this.gridEl.innerHTML;
        this.popupSlider = null;
        this.popupParntersSwiper = null;
        this.is_loading = false;

        return true;
    }

    init() {
        this.setGalleries();
        this.setMore();
        this.checkMoreButton();
    }

    setMore() {
        if (!this.moreEl) return;

        this.onMore = this.onMoreClick.bind(this);
        this.moreEl.addEventListener('click', this.onMore);
    }

    setGalleries() {
        if (!this.galleries) return;

        this.onGallery = debounce(this.onGalleryClick, 1);
        this.galleries.forEach(item => {
            item.addEventListener('click', event => {
                this.onGallery(event, event.currentTarget);
                event.currentTarget.classList.add('is-loading');
                this.galleries.forEach(item => {
                    item.classList.add('is-disabled');
                });
            });
        });
    }

    async onGalleryClick(event, currentTarget) {
        if (this.is_loading) return;

        this.is_loading = true;
        const details = JSON.parse(
            currentTarget.getAttribute('data-galleries-grid-item')
        );

        const { name, gallery, partners } = details;

        const formData = new FormData();
        if (gallery) formData.append('gallery', JSON.stringify(gallery));
        if (partners) formData.append('partners', JSON.stringify(partners));
        if (name) formData.append('name', name);

        const request = await fetch(
            `${home_url}/wp-json/gallery-popup/v1/get`,
            {
                method: 'POST',
                body: formData,
            }
        );

        const res = await request.json();
        const { html } = res;
        if (res) {
            currentTarget.classList.remove('is-loading');
            this.galleries.forEach(item => {
                item.classList.remove('is-disabled');
            });
            this.is_loading = false;
        }

        document.body.insertAdjacentHTML('beforeEnd', html);

        const popupSwiperEl = document.querySelector('[data-popup-swiper]');
        const popupPartnersSwiperEl = document.querySelector(
            '[data-popup-partners-swiper]'
        );
        const popupSwiperPrevEl = document.querySelector(
            '[data-popup-swiper-prev]'
        );
        const popupSwiperNextEl = document.querySelector(
            '[data-popup-swiper-next]'
        );
        const popupSwiperCurrEl = document.querySelector(
            '[data-popup-swiper-curr]'
        );

        const popupEl = document.querySelector('[data-popup]');
        new Popup(popupEl);

        this.setPopupSlider(
            popupSwiperEl,
            popupSwiperPrevEl,
            popupSwiperNextEl,
            popupSwiperCurrEl
        );

        if (!this.popupParntersSwiper) {
            this.popupParntersSwiper = new Swiper(popupPartnersSwiperEl, {
                modules: [Autoplay],
                slidesPerView: 1,
                loop: 1,
                autoplay: {
                    delay: 3000,
                },
            });
        } else {
            this.popupParntersSwiper.destroy();
        }
    }

    async onMoreClick(event) {
        const { currentTarget } = event;

        currentTarget.classList.add('-loading');

        this.currPage += 1;
        await this.loadGalleriesGrid();

        currentTarget.classList.remove('-loading');
    }

    async loadGalleriesGrid() {
        const formData = new FormData();
        if (this.galleriesData)
            formData.append('galleries', JSON.stringify(this.galleriesData));
        if (this.currPage) formData.append('page', this.currPage);
        if (this.lastIndex) formData.append('last_index', this.lastIndex);

        const request = await fetch(
            `${home_url}/wp-json/galleries-grid/v1/get`,
            {
                method: 'POST',
                body: formData,
            }
        );

        const res = await request.json();
        const { html } = res;

        this.html = this.html + html;
        this.gridEl.innerHTML = this.html;

        this.galleries = this.root.querySelectorAll(
            '[data-galleries-grid-item]'
        );
        this.setGalleries();
        this.checkMoreButton();
    }

    checkMoreButton() {
        if (this.currPage === this.maxPages || this.maxPages === 0) {
            this.moreEl.classList.add('-hide');
            this.gridEl.classList.add('-hide-more');
        } else {
            this.moreEl.classList.remove('-hide');
            this.gridEl.classList.remove('-hide-more');
        }
    }

    setPopupSlider(el, prevEl, nextEl, currEl) {
        if (this.popupSlider) this.popupSlider.destroy();

        this.popupSlider = new Swiper(el, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 32,
            navigation: {
                prevEl,
                nextEl,
            },
            breakpoints: {
                1680: {
                    spaceBetween: 40,
                },
            },
        });

        this.popupSlider.on('slideChange', swiper => {
            const { realIndex } = swiper;
            if (currEl) currEl.innerHTML = realIndex + 1;
        });
    }
}

function initGalleriesGrid() {
    const sections = document.querySelectorAll('[data-galleries-grid]');
    if (!sections) return;

    sections.forEach(item => new GalleriesGrid(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initGalleriesGrid();
} else {
    document.addEventListener('DOMContentLoaded', initGalleriesGrid);
}
